// Import React Library
import React from 'react';

// React icons
import EmailIcon from '@mui/icons-material/Email';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationOnIcon from '@mui/icons-material/LocationOn';

// Footer Component
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-row'>
                <div className='footer-col'>
                    <EmailIcon fontSize='large' className='footer-icon'/>            
                    <p>Email:</p>
                    <p>premoldeadosdeleste@gmail.com</p>
                </div>

                <div className='footer-col'>
                    <LocalShippingIcon fontSize='large' className='footer-icon'/>            
                    <p>Entrega inmediata.</p>
                </div>

                <div className='footer-col'>
                    <LocationOnIcon fontSize='large' className='footer-icon'/>            
                    <p>Consultar entrega sin costo en Montevideo, Ciudad de la costa y alrededores.</p>
                </div>

                <div className='footer-col'>
                    <RingVolumeIcon fontSize='large' className='footer-icon'/>            
                    <p>Telefono:</p>
                    <p>098 171 862</p>
                </div>
            </div>

            <div className='footer-row'>
                Premoldeados del Este Ⓡ - Todos los derechos reservados - {new Date().getFullYear()}.
            </div>
        </div>
    );
};

// Export Footer Component
export default Footer;
