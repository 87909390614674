// Import React Library
import React, { useRef, useState, useEffect } from "react";

// Import Icons
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";

// Import Images
import image1 from "../assets/images/our-works/premoldeados-del-este-1.jpeg";
import image2 from "../assets/images/our-works/premoldeados-del-este-2.jpeg";
import image3 from "../assets/images/our-works/premoldeados-del-este-3.jpeg";
import image4 from "../assets/images/our-works/premoldeados-del-este-4.jpeg";
import image5 from "../assets/images/our-works/premoldeados-del-este-5.jpeg";
import image6 from "../assets/images/our-works/premoldeados-del-este-6.jpeg";
import image7 from "../assets/images/our-works/premoldeados-del-este-7.jpeg";
import image8 from "../assets/images/our-works/premoldeados-del-este-8.jpeg";
import image9 from "../assets/images/our-works/premoldeados-del-este-9.jpeg";
import image10 from "../assets/images/our-works/premoldeados-del-este-10.jpeg";
import image11 from "../assets/images/our-works/premoldeados-del-este-11.jpeg";
import image12 from "../assets/images/our-works/premoldeados-del-este-12.jpeg";
import image13 from "../assets/images/our-works/premoldeados-del-este-13.jpeg";
import image14 from "../assets/images/our-works/premoldeados-del-este-14.jpeg";
import image15 from "../assets/images/our-works/premoldeados-del-este-15.jpeg";
import image16 from "../assets/images/our-works/premoldeados-del-este-16.jpeg";
import image17 from "../assets/images/our-works/premoldeados-del-este-17.jpeg";
import image18 from "../assets/images/our-works/premoldeados-del-este-18.jpeg";
import image19 from "../assets/images/our-works/premoldeados-del-este-19.jpeg";
import image20 from "../assets/images/our-works/premoldeados-del-este-20.jpeg";
import image21 from "../assets/images/our-works/premoldeados-del-este-21.jpeg";
import image22 from "../assets/images/our-works/premoldeados-del-este-22.jpeg";
import image23 from "../assets/images/our-works/premoldeados-del-este-23.jpeg";

// Image Marquee Component
const ImageMarquee = () => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setSelectedImage(null);
      }
    };

    if (selectedImage) {
      window.addEventListener("keydown", handleEscKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, [selectedImage]);

  const projects = [
    {
      id: 1,
      title: "Premoldeados del Este",
      image: image1,
      category: "Nuestros trabajos"
    },
    {
      id: 2,
      title: "Premoldeados del Este",
      image: image2,
      category: "Nuestros trabajos"
    },
    {
      id: 3,
      title: "Premoldeados del Este",
      image: image3,
      category: "Nuestros trabajos"
    },
    {
      id: 4,
      title: "Premoldeados del Este",
      image: image4,
      category: "Nuestros trabajos"
    },
    {
      id: 5,
      title: "Premoldeados del Este",
      image: image5,
      category: "Nuestros trabajos"
    },
    {
      id: 7,
      title: "Premoldeados del Este",
      image: image7,
      category: "Nuestros trabajos"
    },
    {
      id: 8,
      title: "Premoldeados del Este",
      image: image8,
      category: "Nuestros trabajos"
    },
    {
      id: 9,
      title: "Premoldeados del Este",
      image: image9,
      category: "Nuestros trabajos"
    },
    {
      id: 10,
      title: "Premoldeados del Este",
      image: image10,
      category: "Nuestros trabajos"
    },
    {
      id: 11,
      title: "Premoldeados del Este",
      image: image11,
      category: "Nuestros trabajos"
    },
    {
      id: 12,
      title: "Premoldeados del Este",
      image: image12,
      category: "Nuestros trabajos"
    },
    {
      id: 13,
      title: "Premoldeados del Este",
      image: image13,
      category: "Nuestros trabajos"
    },
    {
      id: 14,
      title: "Premoldeados del Este",
      image: image14,
      category: "Nuestros trabajos"
    },
    {
      id: 15,
      title: "Premoldeados del Este",
      image: image15,
      category: "Nuestros trabajos"
    },
    {
      id: 16,
      title: "Premoldeados del Este",
      image: image16,
      category: "Nuestros trabajos"
    },
    {
      id: 17,
      title: "Premoldeados del Este",
      image: image17,
      category: "Nuestros trabajos"
    },{
      id: 18,
      title: "Premoldeados del Este",
      image: image18,
      category: "Nuestros trabajos"
    },
    {
      id: 19,
      title: "Premoldeados del Este",
      image: image19,
      category: "Nuestros trabajos"
    },
    {
      id: 20,
      title: "Premoldeados del Este",
      image: image20,
      category: "Nuestros trabajos"
    },
    {
      id: 22,
      title: "Premoldeados del Este",
      image: image22,
      category: "Nuestros trabajos"
    },
    {
      id: 23,
      title: "Premoldeados del Este",
      image: image23,
      category: "Nuestros trabajos"
    }
  ];

  const scroll = (direction) => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const scrollAmount = direction === "left" ? -current.offsetWidth : current.offsetWidth;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      setShowLeftArrow(scrollRef.current.scrollLeft > 0);
    }
  };

  return (
    <div className="relative max-w-7xl mx-auto px-4 py-16">
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={() => setSelectedImage(null)}>
          <div className="relative max-w-4xl w-full p-4">
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-6 right-6 bg-white rounded-full p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 z-50"
              aria-label="Close modal"
            >
              <FaTimes className="w-6 h-6 text-gray-600" />
            </button>
            <img
              src={selectedImage.image}
              alt={selectedImage.title}
              className="w-full h-auto rounded-lg"
              onError={(e) => {
                e.target.src = "https://images.unsplash.com/photo-1590479773265-7464e5d48118";
                e.target.alt = "Fallback Image";
              }}
            />
            <div className="absolute bottom-8 left-8 bg-white bg-opacity-90 p-4 rounded-lg">
              <h3 className="text-xl font-semibold">{selectedImage.title}</h3>
              <p className="text-gray-600">{selectedImage.category}</p>
            </div>
          </div>
        </div>
      )}

      {showLeftArrow && (
        <button
          onClick={() => scroll("left")}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
          aria-label="Scroll left"
        >
          <FaChevronLeft className="w-6 h-6 text-gray-600" />
        </button>
      )}
      <button
        onClick={() => scroll("right")}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
        aria-label="Scroll right"
      >
        <FaChevronRight className="w-6 h-6 text-gray-600" />
      </button>

      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className="flex gap-6 overflow-x-auto scrollbar-hide snap-x snap-mandatory"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {projects.map((project) => (
          <div
            key={project.id}
            className="flex-none w-80 snap-start"
            onClick={() => setSelectedImage(project)}
          >
            <div className="relative group cursor-pointer overflow-hidden rounded-lg">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-64 object-cover transition-all duration-300 group-hover:scale-105 group-hover:grayscale"
                loading="lazy"
                onError={(e) => {
                  e.target.src = "https://images.unsplash.com/photo-1590479773265-7464e5d48118";
                  e.target.alt = "Fallback Image";
                }}
              />
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <h3 className="text-white font-semibold">{project.title}</h3>
                <p className="text-gray-300 text-sm">{project.category}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Export ImageMarquee Component
export default ImageMarquee;